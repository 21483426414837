<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else class="pa-4 grey lighten-4">
      <ConfiguracoesPainel :config="config" @send="editar" />
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Configurações editadas com sucesso"
      @close="success = !success"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import ConfiguracoesPainel from "@/components/configuracoes/ConfiguracoesPainel";
import {
  editarConfiguracoes,
  exibirConfiguracoes,
} from "@/services/configuracoes.js";
export default {
  name: "Configuracoes",
  components: { ConfiguracoesPainel },
  data: () => ({
    breadcrumbs: [
      {
        text: "Configurações",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    config: [],
    loading: false,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    this.exibir();
  },
  methods: {
    async editar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await editarConfiguracoes(event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirConfiguracoes();
        this.config = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
  },
};
</script>

<style></style>
